import { Button, Divider, Stack, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import EventIcon from "@mui/icons-material/Event";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ForumIcon from "@mui/icons-material/Forum";

import logo from "../assets/logo.jpg";
import { Link } from "react-router-dom";
import useBreakpoint from "../hooks/useBreakPoint";

const CustomDivider = () => (
  <Divider orientation="vertical" flexItem sx={{ bgcolor: "white" }} />
);

const MenuList = () => {
  const breakpoint = useBreakpoint();

  return (
    <>
      <Button
        startIcon={<PhotoLibraryIcon />}
        color="inherit"
        component={Link}
        to="/"
      >
        {breakpoint[0] !== "xs" && "Galería"}
      </Button>
      <CustomDivider />
      <Button
        startIcon={<EventIcon />}
        color="inherit"
        component={Link}
        to="/events"
      >
        {breakpoint[0] !== "xs" && "Próximos eventos"}
      </Button>
      <CustomDivider />
      <Button
        startIcon={<Diversity3Icon />}
        color="inherit"
        component={Link}
        to="/about"
      >
        {breakpoint[0] !== "xs" && "¿Quiénes somos?"}
      </Button>
      <CustomDivider />
      <Button
        startIcon={<ForumIcon />}
        color="inherit"
        component={Link}
        to="/contact"
      >
        {breakpoint[0] !== "xs" && "Contacto"}
      </Button>
    </>
  );
};

const Header = () => {
  return (
    <>
      <AppBar position="relative">
        <Toolbar sx={{ py: 1 }}>
          <img
            style={{ height: "50px", width: "50px" }}
            src={logo}
            alt="logo"
          ></img>
          <Stack sx={{ ml: 2, flexGrow: 1 }}>
            <Typography variant="h5">FOTONOBA</Typography>
            <Typography variant="caption">
              Fotógrafos del Noroeste de la pcia. de Buenos Aires
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <MenuList />
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar
        sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center" }}
      >
        <Stack direction={"row"} spacing={2}>
          <MenuList />
        </Stack>
      </Toolbar>
    </>
  );
};

export default Header;
