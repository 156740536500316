import { useEffect, useState } from "react";
import {
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageIcon from "@mui/icons-material/Language";
import useBreakpoint from "../hooks/useBreakPoint";
import Lightbox from "yet-another-react-lightbox";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {
  Fullscreen,
  Slideshow,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import { getActivities, getAuthors, getPhotos } from "../services/data.service";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";

//import { RowsPhotoAlbum } from "react-photo-album";
//import "react-photo-album/rows.css";

const AuthorAction = ({ author }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar src={author.profile} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {[
          ...(author.instagram
            ? [
                {
                  label: "Instagram",
                  icon: InstagramIcon,
                  url: author.instagram,
                },
              ]
            : []),
          ...(author.facebook
            ? [
                {
                  label: "Facebook",
                  icon: FacebookIcon,
                  url: author.facebook,
                },
              ]
            : []),
          ...(author.whatsapp
            ? [
                {
                  label: "Whatsapp",
                  icon: WhatsAppIcon,
                  url: author.whatsapp,
                },
              ]
            : []),
          ...(author.web
            ? [
                {
                  label: "Web",
                  icon: LanguageIcon,
                  url: author.web,
                },
              ]
            : []),
        ].map((option) => (
          <MenuItem
            component="a"
            href={option.url}
            target="_blank"
            onClick={handleClose}
          >
            <ListItemIcon>
              <option.icon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const Photo = ({ photo, onClick, authors }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setLoaded(true);
    img.src = photo.src;
  }, [photo.src]);

  return (
    <ImageListItem
    /*sx={{
        background: "#fff",
        pt: "1rem",
        px: "1rem",
        border: "1px solid darkgrey",
        boxShadow: "5px 10px 10px darkgrey",
      }}*/
    >
      {!loaded ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={250}
          height={150}
        ></Skeleton>
      ) : (
        <img
          srcSet={photo.srcSet}
          src={photo.src}
          alt={photo.alt}
          loading="lazy"
          onClick={onClick}
          style={{ cursor: "pointer" }}
        />
      )}
      <ImageListItemBar
        sx={{ alignItems: "center" }}
        title={authors[photo.author].name}
        actionIcon={<AuthorAction author={authors[photo.author]} />}
        //position="below"
      ></ImageListItemBar>
    </ImageListItem>
  );
};

export default function Gallery() {
  const breakpoint = useBreakpoint();
  const cols = {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
  }[breakpoint];

  const [index, setIndex] = useState(-1);
  const [photos, setPhotos] = useState([]);
  const [authors, setAuthors] = useState({});
  const [activities, setActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([getPhotos(), getAuthors(), getActivities()]).then(
      ([photos, authors, activities]) => {
        setPhotos(photos);
        setAuthors(authors);
        setActivities(activities);
        setSelectedActivities(activities.map((a) => a.id));
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      {/*<RowsPhotoAlbum
        photos={photos.map((photo) => ({ ...photo, width: 1, height: 1 }))}
      />*/}

      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}

      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        {activities.map((activity) => (
          <Chip
            label={activity.name}
            variant={
              selectedActivities.includes(activity.id) ? "filled" : "outlined"
            }
            onDelete={() => {
              if (selectedActivities.includes(activity.id)) {
                setSelectedActivities(
                  selectedActivities.filter((a) => a !== activity.id)
                );
              } else {
                setSelectedActivities([...selectedActivities, activity.id]);
              }
            }}
            deleteIcon={
              selectedActivities.includes(activity.id) ? (
                <CancelIcon />
              ) : (
                <AddCircleIcon />
              )
            }
          />
        ))}
      </Stack>

      <ImageList variant="masonry" cols={cols} gap={5} sx={{ m: 2 }}>
        {photos
          .filter((photo) => selectedActivities.includes(photo.activity))
          .map(({ thumbnail, alt, author, activity }) => ({
            src: thumbnail,
            alt: alt,
            author: author,
            activity: activity,
          }))
          .map((item, index) => (
            <Photo
              photo={item}
              onClick={() => setIndex(index)}
              authors={authors}
            />
          ))}
      </ImageList>

      <Lightbox
        index={index}
        slides={photos
          .filter((photo) => selectedActivities.includes(photo.activity))
          .map(({ full, alt }) => ({ src: full, alt: alt }))}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Zoom]}
      />
    </>
  );
}
