import { getEvents } from "../services/data.service";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Masonry } from "@mui/lab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceIcon from "@mui/icons-material/Place";
import ShareIcon from "@mui/icons-material/Share";
import MessageIcon from "@mui/icons-material/Message";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getEvents().then((results) => {
      setEvents(results);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}

      <Box>
        <Masonry spacing={2} columns={{ xs: 1, sm: 2, md: 3 }}>
          {events.map((event) => (
            <Card>
              <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={event.photo}
                title={event.title}
                alt={event.title}
              />
              <CardContent>
                <Chip
                  color="primary"
                  size="small"
                  label={event.type}
                  gutterBottom
                />
                <Typography gutterBottom variant="h5" component="div">
                  {event.title}
                </Typography>
                <Typography gutterBottom variant="body2">
                  {event.description}
                </Typography>
                <List>
                  {[
                    { icon: <CalendarMonthIcon />, label: event.date },
                    { icon: <AccessTimeIcon />, label: event.hour },
                    { icon: <PlaceIcon />, label: event.address },
                  ].map((item) => (
                    <ListItem disablePadding disableGutters>
                      <ListItemIcon sx={{ minWidth: "35px" }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <Divider />
              <CardActions spacing={2}>
                <IconButton>
                  <ShareIcon />
                </IconButton>
                <IconButton>
                  <MessageIcon />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        </Masonry>
      </Box>
    </>
  );
};

export default Events;
