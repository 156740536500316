const Photo = (props) => {
  return {
    full: props.completa,
    thumbnail: props.miniatura,
    alt: props.descripcion,
    width: props.width,
    height: props.height,
    author: props.autor,
    activity: props.actividad,
  };
};

export default Photo;
