import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";

const Contact = () => {
  const [nombre, setNombre] = useState("");
  const [consulta, setConsulta] = useState("");

  const [nombreError, setNombreError] = useState(false);
  const [consultaError, setConsultaError] = useState(false);

  const handleSubmit = () => {
    setNombreError(nombre === "");
    setConsultaError(consulta === "");

    if (nombre && consulta) {
      //window.open(generateURLForConsulta(nombre, consulta), "_blank");
    }
  };

  return (
    <>
      <Stack spacing={2} sx={{width: "70%" }}>
        <TextField
          id="nombre"
          label="Nombre"
          required
          error={nombreError}
          onChange={(e) => setNombre(e.target.value)}
        />
        <TextField
          id="consulta"
          label="Consulta"
          required
          error={consultaError}
          multiline
          rows={6}
          onChange={(e) => setConsulta(e.target.value)}
        />
        <Button
          variant="contained"
          size="large"
          startIcon={<SendIcon />}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </Stack>
    </>
  );
};

export default Contact;
