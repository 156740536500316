import Markdown from "react-markdown";
import aboutUs from "../assets/about-us.md";
import { useEffect, useState } from "react";
import rehypeRaw from "rehype-raw";
import { CircularProgress } from "@mui/material";

const AboutUs = () => {
  const [text, setText] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(aboutUs)
      .then((res) => res.text())
      .then((text) => {
        setText(text);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}

      <Markdown urlTransform={(value) => value} rehypePlugins={[rehypeRaw]}>
        {text}
      </Markdown>
    </>
  );
};

export default AboutUs;
