import { readRemoteFile } from "react-papaparse";
import Photo from "../model/Photo.class";
import Author from "../model/Author.class";
import Activity from "../model/Activity.class";
import Event from "../model/Event.class";

const photosURL =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vTK3tHgzbPfiQXGY8PjSRbNUGr0O8QleUmMgdcdiP7ozhmNuzbCtSx51RqbnC8NME7kIbxIc0oTj7DH/pub?gid=0&single=true&output=csv";
const authorsURL =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vTK3tHgzbPfiQXGY8PjSRbNUGr0O8QleUmMgdcdiP7ozhmNuzbCtSx51RqbnC8NME7kIbxIc0oTj7DH/pub?gid=996486842&single=true&output=csv";
const activitiesURL =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vTK3tHgzbPfiQXGY8PjSRbNUGr0O8QleUmMgdcdiP7ozhmNuzbCtSx51RqbnC8NME7kIbxIc0oTj7DH/pub?gid=1724887138&single=true&output=csv";
const eventsURL =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vTK3tHgzbPfiQXGY8PjSRbNUGr0O8QleUmMgdcdiP7ozhmNuzbCtSx51RqbnC8NME7kIbxIc0oTj7DH/pub?gid=558047871&single=true&output=csv";

export const getPhotos = () => {
  return new Promise((resolve, reject) => {
    readRemoteFile(photosURL, {
      header: true,
      download: true,
      complete: (result) => {
        resolve(result.data.map((photo) => Photo(photo)));
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const getAuthors = () => {
  return new Promise((resolve, reject) => {
    readRemoteFile(authorsURL, {
      header: true,
      download: true,
      complete: (result) => {
        const authors = {};
        result.data.forEach((author) => {
          authors[author.id] = Author(author);
        });
        resolve(authors);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const getActivities = () => {
  return new Promise((resolve, reject) => {
    readRemoteFile(activitiesURL, {
      header: true,
      download: true,
      complete: (result) => {
        resolve(result.data.map((activity) => Activity(activity)));
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const getEvents = () => {
  return new Promise((resolve, reject) => {
    readRemoteFile(eventsURL, {
      header: true,
      download: true,
      complete: (result) => {
        resolve(result.data.map((event) => Event(event)));
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};
