import {
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import AboutUs from "./components/AboutUs";
import Events from "./components/Events";
import { Route, Routes } from "react-router-dom";
import Contact from "./components/Contact";

const defaultTheme = createTheme({});

const ContainerCentered = ({ children }) => (
  <Container
    component="main"
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      py: 2,
    }}
  >
    {children}
  </Container>
);

const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />

      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <Container
                component="main"
                maxWidth="100%"
                disableGutters
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Gallery />
              </Container>
            }
          />
          <Route
            path="events"
            element={
              <ContainerCentered>
                <Events />
              </ContainerCentered>
            }
          />
          <Route
            path="contact"
            element={
              <ContainerCentered>
                <Contact />
              </ContainerCentered>
            }
          />
          <Route
            path="about"
            element={
              <ContainerCentered>
                <AboutUs />
              </ContainerCentered>
            }
          />
        </Route>
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
