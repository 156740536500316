import { useMediaQuery, useTheme } from '@mui/material';

const useBreakpoint = () => {
  const theme = useTheme();

  // Invocar useMediaQuery directamente para cada uno de los breakpoints
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  // Crear una lista con los breakpoints que coincidan
  const matchingBreakpoints = [];
  if (isXs) matchingBreakpoints.push('xs');
  if (isSm) matchingBreakpoints.push('sm');
  if (isMd) matchingBreakpoints.push('md');
  if (isLg) matchingBreakpoints.push('lg');
  if (isXl) matchingBreakpoints.push('xl');

  return matchingBreakpoints;
};

export default useBreakpoint;