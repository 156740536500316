const Event = (props) => {
  return {
    title: props.titulo,
    type: props.tipo,
    date: props.fecha,
    hour: props.hora,
    address: props.direccion,
    description: props.descripcion,
    photo: props.foto,
  };
};

export default Event;
