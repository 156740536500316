import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Divider } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Divider />
      <Box component="footer" sx={{ my: 2 }}>
        <Typography variant="h5" align="center">
          FOTONOBA
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Fotógrafos del noroeste de la Provincia de Buenos Aires
        </Typography>
        <Typography variant="body2" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://ebt.ar/">
            El Bloque Tecno
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </>
  );
};
export default Footer;
